
document.addEventListener('DOMContentLoaded', function(){
	

	iniciarApp();
});

function iniciarApp() {
	navegacionFija();
	scrollNav();
	seleccion();
	
}

function navegacionFija() {
    const barra = document.querySelector('.header');
    const sobreNosotros = document.querySelector('.nosotros');
    const body = document.querySelector('body');

    window.addEventListener('scroll', function(){
        //console.log(sobreNosotros.getBoundingClientRect());

        if(sobreNosotros.getBoundingClientRect().top<0){
			//console.log('Ya pasamos');
                barra.classList.add('fijo');
               body.classList.add('body-scroll');
        }else {
            barra.classList.remove('fijo');
           body.classList.remove('body-scroll');
        }

    });
}

function scrollNav(){
	const enlaces = document.querySelectorAll('.navegacion a');
	enlaces.forEach( enlace=> {
		enlace.addEventListener('click', function(e){
			e.preventDefault();
			//console.log(e.target.attributes.href.value);
			const seccionScroll = e.target.attributes.href.value;
			const seccion = document.querySelector(seccionScroll);
			seccion.scrollIntoView({ behavior: "smooth"});
		});

	});
}

function seleccion(){

	
		$('.filter').click(function () {
					$(this).addClass('active').siblings().removeClass('active');
				let valor = $(this).attr('data-nombre');
				if (valor == 'todos') {
						$('.cont-work').show('1000');
					} else {
						$('.cont-work').not('.' + valor).hide('1000');
					$('.cont-work').filter('.' + valor).show('1000');
					}
			
				}); 

		

	// const filter = document.querySelectorAll('.filter');
	// filter.forEach( filtro=> {
	// 	filtro.addEventListener('click', function(e){
	// 		console.log(e.target);
	// 		console.log(e.target.classList);
	// 		console.log(e.target.classList.value);
	// 		if(e.target.classList.value === 'filter'){
	// 			console.log("Es igual");
	// 			filtro.classList.add('activo');
	// 			console.log(e.target.classList.value);
	// 			filtro.classList.remove('body-scroll');
	// 			//body.classList.remove('body-scroll');
	// 		}else {
	// 			console.log("No Es igual");
	// 		}

   
}



//Inicio de Typed

const typed = new Typed('.typed', {
    // strings:[
    //     '<i class="mascota">Gato</i>',
    //     '<i class="mascota">Perro</i>',
    //     '<i class="mascota">Conejo</i>',
    //     '<i class="mascota">Pez</i>'
    // ],

    stringsElement: '#cadenas-texto', // ID del elemento que contiene cadenas de texto a mostrar.
	typeSpeed: 75, // Velocidad en mlisegundos para poner una letra,
	startDelay: 300, // Tiempo de retraso en iniciar la animacion. Aplica tambien cuando termina y vuelve a iniciar,
	backSpeed: 75, // Velocidad en milisegundos para borrrar una letra,
	smartBackspace: false, // Eliminar solamente las palabras que sean nuevas en una cadena de texto.
	shuffle: false, // Alterar el orden en el que escribe las palabras.
	backDelay: 1500, // Tiempo de espera despues de que termina de escribir una palabra.
	loop: true, // Repetir el array de strings
	loopCount: false, // Cantidad de veces a repetir el array.  false = infinite
	showCursor: true, // Mostrar cursor palpitanto
	cursorChar: '|', // Caracter para el cursor
	contentType: 'html', // 'html' o 'null' para texto sin formato



});

//Fin de Typed


